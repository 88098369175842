// React
import React, { useEffect, useState, useRef } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import _get from 'lodash.get';
import { StickyContainer } from 'react-sticky';
import useScrollSpy from 'react-use-scrollspy';
import { useGlobal } from 'reactn';

// Utilities
import { localize, scrollTo, scrollTop } from 'utilities';
import { useTheme, useResponsive } from 'hooks';

// Components
import SEO from 'components/seo';
import { PageNavigationSticky } from 'components/pageNavigation';
import ProductCategory from 'components/_shop/productCategory';

const ShopPageComponent = props => {
    const {
        content,
        content: {
            seoMetaTags,
            categories,
            filter,
            espresso,
            equipment,
            other,
            giftCard,
            barGiftCard = null,
            subscriptionGiftCard = null,
            presale,
            seasonCategoryProducts,
        },
        location,
        pageContext,
        pageContext: { locale },
    } = props;

    // Category
    const [category, setCategory] = useState(categories[0]);

    // Breakpoint
    const breakpoint = useResponsive();

    // Set offsets for scrolling based on breakpoints
    const [offsetBase, setOffsetBase] = useState(130);
    useEffect(() => {
        switch (breakpoint) {
            case 0:
            case 10:
                setOffsetBase(80);
                break;
            case 20:
                setOffsetBase(90);
                break;
            case 30:
                setOffsetBase(110);
                break;
            case 40:
                setOffsetBase(130);
                break;
        }
    }, [breakpoint]);

    // Product category by location
    useEffect(() => {
        // Get category from state
        const stateCategory = _get(location, 'state.category', null);

        // Is there a category from content that corresponds to the category name from state?
        const categoryList = categories.filter(
            cat => cat.category === stateCategory
        );
        const category =
            categoryList.length > 0 ? categoryList[0] : categories[0];
        setCategory(category);

        if (stateCategory) {
            // Scroll to section
            scrollToSection(
                categories.findIndex(item => item.name === category.name),
                250
            );
        }
    }, [location]);

    // Set theme
    useTheme(_get(category, 'theme', content.theme), [category]);
    const [theme, setTheme] = useState(s.themeColor(content.theme));
    useEffect(() => {
        setTheme(s.themeColor(_get(category, 'theme', content.theme)));
    }, [category]);

    // Scrollspy
    const categoryRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeCategory = useScrollSpy({
        sectionElementRefs: categoryRefs,
        offsetPx: -(offsetBase + 50),
    });
    useEffect(() => {
        setCategory(categories[activeCategory]);
    }, [activeCategory]);

    // Header
    const [, setForceHeaderDisabled] = useGlobal('forceHeaderDisabled');

    // Scroll to category
    const scrollToSection = (index, timeout = 50) => {
        // Disable header
        setForceHeaderDisabled(true);

        // Make the scroll
        setTimeout(() => {
            scrollTo(scrollTop(categoryRefs[index].current), () => {}, null);
        }, timeout);

        // Enable header
        setTimeout(() => {
            setForceHeaderDisabled(false);
        }, timeout + 100);
    };

    // Make array
    const presaleProducts = presale
        ? Array.isArray(presale)
            ? presale
            : [presale]
        : [];

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />

                <StickyContainer>
                    <PageNavigationSticky {...{ theme }}>
                        {categories.map(
                            (item, index) =>
                                item.active && (
                                    <ShopNavigationElement
                                        key={item.name}
                                        {...{
                                            active:
                                                item.name ===
                                                _get(category, 'name', null),
                                            onClick() {
                                                scrollToSection(index);
                                            },
                                        }}>
                                        {item.name}
                                    </ShopNavigationElement>
                                )
                        )}
                    </PageNavigationSticky>
                    <ContentWrapper>
                        {categories[0].active && (
                            <ShopElementWrapper
                                theme={s.themeColor({
                                    color: 'categories',
                                    index: 3,
                                })}
                                ref={categoryRefs[0]}>
                                <ProductCategory
                                    {...{
                                        location,
                                        pageContext,
                                        background: s.themeColor({
                                            color: 'categories',
                                            index: 3,
                                        }),
                                        products: [...seasonCategoryProducts],
                                    }}
                                />
                            </ShopElementWrapper>
                        )}

                        <ShopElementWrapper
                            theme={s.themeColor({
                                color: 'categories',
                                index: 1,
                            })}
                            ref={categoryRefs[1]}>
                            <ProductCategory
                                {...{
                                    location,
                                    pageContext,
                                    background: s.themeColor({
                                        color: 'categories',
                                        index: 1,
                                    }),
                                    products: [
                                        ...filter,
                                        ...presaleProducts.filter(
                                            p => p.showInCategory === 'filter'
                                        ),
                                    ],
                                    bundles: categories[1].bundles.filter(
                                        bundle => bundle.active
                                    ),
                                }}
                            />
                        </ShopElementWrapper>
                        <ShopElementWrapper
                            theme={s.themeColor({
                                color: 'categories',
                                index: 2,
                            })}
                            ref={categoryRefs[2]}>
                            <ProductCategory
                                {...{
                                    location,
                                    pageContext,
                                    background: s.themeColor({
                                        color: 'categories',
                                        index: 2,
                                    }),
                                    products: [
                                        ...espresso,
                                        ...presaleProducts.filter(
                                            p => p.showInCategory === 'espresso'
                                        ),
                                    ],
                                    bundles: categories[2].bundles.filter(
                                        bundle => bundle.active
                                    ),
                                }}
                            />
                        </ShopElementWrapper>
                        <ShopElementWrapper
                            theme={s.themeColor({
                                color: 'categories',
                                index: 3,
                            })}
                            ref={categoryRefs[3]}>
                            <ProductCategory
                                {...{
                                    location,
                                    pageContext,
                                    background: s.themeColor({
                                        color: 'categories',
                                        index: 3,
                                    }),
                                    products: [
                                        ...equipment,
                                        ...presaleProducts.filter(
                                            p =>
                                                p.showInCategory ===
                                                'equiptment'
                                        ),
                                    ],
                                    bundles: categories[3].bundles.filter(
                                        bundle => bundle.active
                                    ),
                                }}
                            />
                        </ShopElementWrapper>
                        <ShopElementWrapper
                            theme={s.themeColor({
                                color: 'categories',
                                index: 3,
                            })}
                            ref={categoryRefs[4]}>
                            <ProductCategory
                                {...{
                                    location,
                                    pageContext,
                                    background: s.themeColor({
                                        color: 'categories',
                                        index: 3,
                                    }),
                                    products: [
                                        giftCard,
                                        barGiftCard,
                                        subscriptionGiftCard,
                                        ...other,
                                        ,
                                        ...presaleProducts.filter(
                                            p => p.showInCategory === 'other'
                                        ),
                                    ].filter(p => p),
                                    bundles: categories[4].bundles.filter(
                                        bundle => bundle.active
                                    ),
                                }}
                            />
                        </ShopElementWrapper>
                    </ContentWrapper>
                </StickyContainer>
            </s.layout.PageContainer>
        </>
    );
};

const ShopElementWrapper = styled.div`
    ${props => s.layout.fullWidthBackground(props.theme.default())};
    &:first-of-type {
        ${s.responsive.property('padding-top', {
            0: 160,
            20: 240,
        })};

        ${s.responsive.property('margin-top', {
            0: -160,
            20: -240,
        })};
    }
`;

const ShopNavigationElement = styled.span`
    cursor: pointer;

    transition: opacity 0.4s;
    will-change: opacity;
    white-space: nowrap;
    opacity: 0.3;

    &:hover {
        opacity: 1;
    }

    &:focus,
    &:active {
        opacity: 0.3;
    }

    ${props =>
        props.active &&
        css`
            opacity: 1;
        `}
`;

const ContentWrapper = styled.div`
    ${s.responsive.property('margin-top', {
        0: 80,
        30: 100,
    })};
`;

const NamedShopPageComponent = props => {
    const { locale } = props.pageContext;
    const {
        allDatoCmsPageShop,
        allDatoCmsFilterCoffee,
        allDatoCmsEspresso,
        allDatoCmsEquipment,
        allDatoCmsOtherProduct,
        allDatoCmsGiftCard,
        allDatoCmsBarGiftCard,
        allDatoCmsSubscriptionGiftCard,
        allDatoCmsPresaleProduct,
    } = useStaticQuery(graphql`
        query {
            allDatoCmsPageShop {
                edges {
                    node {
                        locale
                        theme {
                            ...Theme
                        }
                        seoMetaTags {
                            ...SEO
                        }
                        categories {
                            name
                            category
                            active
                            theme {
                                ...Theme
                            }
                            bundles {
                                ...ProductBundleFragment
                            }
                        }
                        seasonCategoryProducts {
                            ... on DatoCmsBundle {
                                ...ProductBundleFragment
                            }
                            ... on DatoCmsFilterCoffee {
                                ...ProductFilterCoffeeFragment
                            }
                            ... on DatoCmsEspresso {
                                ...ProductEspressoFragment
                            }
                            ... on DatoCmsEquipment {
                                ...ProductEquipmentFragment
                            }
                            ... on DatoCmsOtherProduct {
                                ...ProductOtherProductFragment
                            }
                            ... on DatoCmsPresaleProduct {
                                ...ProductPresaleFragment
                            }
                            ... on DatoCmsGiftCard {
                                ...ProductGiftCardFragment
                            }
                            ... on DatoCmsBarGiftCard {
                                ...ProductBarGiftCardFragment
                            }
                            ... on DatoCmsSubscriptionGiftCard {
                                ...ProductSubscriptionGiftCardFragment
                            }
                        }
                    }
                }
            }
            allDatoCmsFilterCoffee(
                filter: { active: { eq: true } }
                sort: { fields: [position], order: DESC }
            ) {
                edges {
                    node {
                        ...ProductFilterCoffeeFragment
                    }
                }
            }
            allDatoCmsEspresso(
                filter: { active: { eq: true } }
                sort: { fields: [position], order: DESC }
            ) {
                edges {
                    node {
                        ...ProductEspressoFragment
                    }
                }
            }
            allDatoCmsEquipment(
                filter: { active: { eq: true } }
                sort: { fields: [position], order: DESC }
            ) {
                edges {
                    node {
                        ...ProductEquipmentFragment
                    }
                }
            }
            allDatoCmsOtherProduct(
                filter: { active: { eq: true } }
                sort: { fields: [position], order: DESC }
            ) {
                edges {
                    node {
                        ...ProductOtherProductFragment
                    }
                }
            }
            allDatoCmsGiftCard(filter: { active: { eq: true } }) {
                edges {
                    node {
                        ...ProductGiftCardFragment
                    }
                }
            }
            allDatoCmsBarGiftCard(filter: { active: { eq: true } }) {
                edges {
                    node {
                        ...ProductBarGiftCardFragment
                    }
                }
            }
            allDatoCmsSubscriptionGiftCard(filter: { active: { eq: true } }) {
                edges {
                    node {
                        ...ProductSubscriptionGiftCardFragment
                    }
                }
            }
            allDatoCmsPresaleProduct(
                filter: { active: { eq: true } }
                sort: { fields: [position], order: DESC }
            ) {
                edges {
                    node {
                        ...ProductPresaleFragment
                    }
                }
            }
        }
    `);

    return (
        <ShopPageComponent
            {...{
                content: {
                    ...localize(allDatoCmsPageShop, locale),
                    filter: localize(allDatoCmsFilterCoffee, locale),
                    espresso: localize(allDatoCmsEspresso, locale),
                    equipment: localize(allDatoCmsEquipment, locale),
                    other: localize(allDatoCmsOtherProduct, locale),
                    giftCard: localize(allDatoCmsGiftCard, locale),
                    barGiftCard: localize(allDatoCmsBarGiftCard, locale),
                    subscriptionGiftCard: localize(
                        allDatoCmsSubscriptionGiftCard,
                        locale
                    ),
                    presale: localize(allDatoCmsPresaleProduct, locale),
                },
                ...props,
            }}
        />
    );
};

export default NamedShopPageComponent;
