// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { lineBreaks, localizeLink } from 'utilities';
import { TransitionLink, transitionNavigate } from 'transition';
import { useTouch, useResponsive } from 'hooks';

// Components
import Button from 'components/button';
import Image from 'components/image';

import { ReactComponent as SvgPlus } from 'src/images/plus.svg';

const ProductBundleComponent = props => {
    const {
        location,
        bundle: { name, teaser, products, slug, sku },
        pageContext: { locale, labels, allSwellProducts },
    } = props;

    // Touch
    const touch = useTouch();

    // Breakpoint
    const breakpoint = useResponsive();

    // Currency
    const [currency] = useGlobal('currency');

    // Swell product (simple)
    const swellProduct = allSwellProducts[sku] ?? {};

    return (
        <>
            <ProductBundle>
                <Title>{name}</Title>
                <Teaser
                    dangerouslySetInnerHTML={{
                        __html: lineBreaks(teaser),
                    }}
                />
                <ProductList {...{ touch }}>
                    {products.map((product, index) => {
                        return (
                            <Product
                                key={product.id}
                                {...{
                                    theme: product.theme
                                        ? s.themeColor(product.theme)
                                        : s.color('white'),
                                }}>
                                <TransitionLink
                                    from={location}
                                    to={localizeLink(
                                        `/shop/${product.slug}/`,
                                        locale
                                    )}
                                    transition={[
                                        'default',
                                        {
                                            theme: product.theme
                                                ? s.themeColor(product.theme)
                                                : s.color('white'),
                                        },
                                    ]}
                                    title={product.name}>
                                    {product.name}
                                </TransitionLink>
                                {products.length > index + 1 && (
                                    <b>
                                        <SvgPlus />
                                    </b>
                                )}
                                {!touch && breakpoint > 20 && (
                                    <div>
                                        <Image
                                            {...{
                                                source: product.thumbnail.fluid,
                                                alt: product.name,
                                            }}
                                        />
                                    </div>
                                )}
                            </Product>
                        );
                    })}
                </ProductList>
                <Price>
                    {`${swellProduct?.price} ${labels.CURRENCY[currency]}`}
                </Price>
                <Button
                    {...{
                        onClick() {
                            transitionNavigate({
                                from: location,
                                to: localizeLink(`/shop/${slug}/`, locale),
                            });
                        },
                        type: 'secondary',
                        label: labels.PAGES.HOME.DISCOVER,
                        arrow: true,
                    }}
                />
            </ProductBundle>
        </>
    );
};

const ProductBundle = styled.div`
    ${s.responsive.property(['padding-top', 'padding-bottom'], {
        0: 100,
        20: 160,
    })};

    ${s.layout.fullWidthBackground(s.color('white').default())};
    color: ${s.color('black').default()};
`;

const ProductList = styled.div`
    z-index: 10;
    position: relative;

    ${s.grid.columns('width', {
        0: 4,
        20: 6,
        30: 12,
    })};

    ${s.grid.columns('padding-right', {
        0: () => '0',
        30: 4,
        40: 3.5,
    })};
`;

const Product = styled.div`
    ${s.fonts.style('h4')};
    line-height: 1.2 !important;
    color: ${props => props.theme.default()};
    display: inline-block;

    a {
        ${s.fonts.style('h4')};
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0.09em;
            left: 0;
            width: 100%;
            height: 2px;
            background: currentColor;
            transform: scaleX(0);
            transform-origin: 0 0;
            will-change: transform;
            transition: transform;
            transition-duration: 400ms;
            transition-delay: 0ms;
            transition-timing-function: ${s.easings.smooth.out};
        }
    }

    b {
        ${s.responsive.property('margin', {
            0: '0 0.5em',
            20: '0 0.3em',
        })};

        display: inline-flex;
        width: 0.4em;
        height: 0.7em;
        position: relative;
        z-index: 2;
    }

    div {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        display: flex;
        align-items: flex-end;
        transition: opacity 600ms;
        pointer-events: none;
        opacity: 0;

        ${s.grid.columns('width', {
            0: 4,
            30: 4,
            40: 3,
        })};

        ${s.grid.columns('height', {
            0: 4,
            30: 4,
            40: 3,
        })};

        img {
            max-width: 100%;
            object-fit: contain !important;
        }
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;

        ${s.responsive.property('transform', {
            0: 'translate(-50%, -50%) scale(0.6)',
            20: 'translate(-50%, -50%) scale(0.9)',
            30: 'translate(-50%, -50%) scale(1)',
        })};

        path {
            fill: ${s.color('white').text()};
        }
    }

    ${s.responsive.mq(30)} {
        &:hover {
            ${props =>
                !props.touch &&
                css`
                    div {
                        opacity: 1;
                    }
                    a::after {
                        transform: scaleX(1);
                    }
                `}
        }
    }
`;

const Title = styled.div`
    ${s.layout.sectionTitle()};
    position: relative;
    z-index: 11;
`;

const Teaser = styled.h2`
    ${s.grid.columns('width', {
        0: 4,
        20: 6,
        30: 12,
    })};

    ${s.fonts.style('h3')};

    margin-bottom: 0.8em;
    position: relative;
    z-index: 11;
    pointer-events: none;
`;

const Price = styled.div`
    ${s.fonts.style('h9')};
    margin-top: 1.5em;
    margin-bottom: 0.8em;
`;

export default ProductBundleComponent;
