// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Components
import Product from 'components/_shop/product';
import ProductBundle from 'components/_shop/productBundle';

const ProductCategoryComponent = ({
    location,
    products = [],
    bundles = [],
    background,
    pageContext,
}) => {
    return (
        <>
            <ProductCategory>
                {products.length > 0 && (
                    <Products>
                        {products.map(product => (
                            <Product
                                key={product.id}
                                {...{
                                    background,
                                    product,
                                    pageContext,
                                    location,
                                }}
                            />
                        ))}
                    </Products>
                )}
                {bundles.length > 0 && (
                    <Bundles>
                        {bundles.map(bundle => (
                            <ProductBundle
                                key={bundle.id}
                                {...{ bundle, pageContext, location }}
                            />
                        ))}
                    </Bundles>
                )}
            </ProductCategory>
        </>
    );
};

const ProductCategory = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 70vh;

    ${s.responsive.property('padding-top', {
        0: 0,
        20: '2em',
    })};
`;

const Products = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Bundles = styled.div``;

export default ProductCategoryComponent;
